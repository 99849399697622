body {
  font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  background-color: rgba(0,0,0,0.1);
  /* background: url('/img/bg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover; */
}

.brand {
  /* padding: 20px 0 20px; */
  text-align: left;
  font-size: 5em;
  min-width: 150px;
}

.brand-before,
.brand-name {
  text-align: center;
}

.brand-before {
  margin: 15px 0;
}

.brand-name {
  margin: 0;
  font-size: 4em;
}

img.ui.image {
  display: inline;
}

.headerbar {
  width:750px;
  margin-bottom: 14px;
  border-radius: .3125em !important;
  border: 1px solid rgba(0,0,0,.1);
}

.navbar {
  background: rgba(255,255,255,0.9) !important;
  margin-top: 1em !important;
}

.navbar .ui.menu .item a {
  color: rgb(0, 0, 0, .6);
}

.box {
  background: rgba(255,255,255,0.9) !important;
  border-top: 1px solid rgba(0,0,0,.1) !important;
  border-bottom: 1px solid rgba(0,0,0,.1) !important;
  margin-top:16px !important;
}